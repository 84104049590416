/**
 * vuex状态管理
 */
import vue from 'vue'
import Vuex from 'vuex'
import router,{resetRouter} from "../router"
// 导入插件，用于在浏览器的localStorage中持久化Vuex状态
import createPersistedState from 'vuex-persistedstate'
vue.use(Vuex)

// 遍历路由配置，找到路径为/的路由项
function addNewRoute(menuList) {
    console.log(menuList)// 打印菜单列表
    let routes = router.options.routes // 获取当前的路由配置
    console.log(routes)
    routes.forEach(routeItem=>{
        if(routeItem.path=="/Index"){
             // 对于每个菜单项，创建一个新的子路由
            menuList.forEach(menu=>{
                let childRoute =  {
                    path:'/'+menu.menuclick,
                    name:menu.menuname,
                    meta:{
                        title:menu.menuname
                    },
                    component:()=>import('../components/'+menu.menucomponent)
                }

                routeItem.children.push(childRoute)
            })
        }
    })

    resetRouter()
    router.addRoutes(routes)
}

export default new Vuex.Store({
    //数据，相当于data
    state: {
        menu: []    // 存储菜单项数据的数组
    },
    //里面定义方法，操作state方发
    mutations: {
        setMenu(state,menuList) {
            state.menu = menuList

            addNewRoute(menuList)
        }
    },
    getters: {
        getMenu(state) {
            return state.menu
        }
    },
    // 插件，用于在浏览器的localStorage中持久化Vuex状态
    plugins:[createPersistedState()]
})