
import VueRouter from 'vue-router';

const routes = [
    {
        path:'/',
        name:'login',
        component:()=>import('../components/Login')
    },
    {
        path:'/Index',
        name:'index',
        component:()=>import('../components/Index'),
        children:[
            {
                path:'/Home',
                name:'home',
                meta:{
                    title:'首页'
                },
                component:()=>import('../components/Home')
            },
            {
                path:'/Price',
                name:'price',
                meta:{
                    title:'商品表'
                },
                component:()=>import('../components/price/PriceManage.vue')
            }
        ]
    }
]

const router = new VueRouter({
    mode:'hash',
    routes
})

/**
 * 重置路由配置。
 * 
 * 该函数用于重新初始化VueRouter的路由匹配器，通常在应用需要动态更新路由配置时使用。
 * 通过创建一个新的VueRouter实例并设置为空路由数组，来实现路由的重置。
 * 这样做可以避免直接修改现有的路由配置，保证路由的重新配置不会影响到已经存在的路由状态。
 * 
 * @export 使得该函数可以在外部模块中被导入和调用。
 */
export function resetRouter() {
    // 创建一个新的VueRouter实例，使用空的路由数组来重置路由配置
    router.matcher = new VueRouter({
        mode: 'hash',
        routes: []
    }).matcher
}

/**
 * 修改VueRouter的push方法，以捕获并处理可能的错误。
 * 这样的修改使得在路由推送到新位置时，如果发生错误，我们可以有机会对其进行处理。
 * 
 * @param {string|Object} to 要导航到的位置。可以是一个字符串路径或一个路由对象。
 * @returns {Promise} 返回一个Promise对象，允许对导航结果进行异步处理。
 */
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
export default router;